.loginFormContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-position: bottom;
  height: 100vh;
  width: 100vw;
  text-align: center;
  margin: auto;
}

.text {
  color: #0071bc;
  font-size: 16px;
  padding: 15px;
  margin: 0;
}

.oneLoginButton {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.oneLoginButton span:hover {
  color: #0071bc;
}

.loginFormWrapper {
  width: 30%;
  padding: 30px;
  background-color: white;
  min-width: 300px;
}

.buttonsWrapper {
  display: flex;
  justify-content: space-between;
}

.buttonsWrapper > span {
  align-self: flex-end;
  color: #3e99ee;
  cursor: pointer;
}

.buttonsWrapper > span:hover {
  color: #6dbafa;
}
