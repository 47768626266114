.submitBtnCol :global(.ant-form-item) {
  margin-bottom: 0;
}

.submitBtn {
  float: right;
}

.rowReverse :global(.ant-form-item-row) {
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.rowReverse :global(.ant-form-item-row .ant-form-item-control) {
  width: 30px;
  flex-grow: initial;
}

.rowReverse :global(.ant-form-item-row .ant-form-item-label) {
  padding-bottom: 0px;
  align-self: center;
}
