.permissionRow {
  margin-bottom: 10px;
}

.permissionRow:last-child {
  margin-bottom: 0;
}

.permissionAttributeCol {
  margin-left: 20px;
}
